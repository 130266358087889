@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  background-color:#202023 ;
  color-scheme: dark;

}
.body{
  -webkit-text-size-adjust: 100%
}
.arrow{
  text-orientation: upright;
  transform: rotate(270deg);


}
.project-description, .project-image{
  grid-row: 1/2;
}
.project-description-right, .project-image-right{
  grid-row: 1/2;
}
.project-image{
  grid-column: 1/6;
}
.project-description{
  grid-column: 5/-1;
}
.project-image-right{
  grid-column: -1/-6;
}
.project-description-right{
  grid-column: 5/1;
}

.oval {
  width: 370px;
  height: 270px;
  background: #ff0000;
  border-radius: 260px / 190px;
  background-color: #363535;
  background-blend-mode: soft-light;
}
@media (max-width:640px	)  {
  .oval {
    width: 190px;
    height: 120px;
    background: #ff0000;
    border-radius: 120px / 80px;
    background-color: #363535;
    background-blend-mode: soft-light;
  }
}
@media (min-width: 1538px) and (min-height: 1175px) {
  .oval {
    width: 690px;
    height: 570px;
    background: #ff0000;
    border-radius: 560px / 490px;
    background-color: #363535;
    background-blend-mode: soft-light;
  }
 }
@media (min-width: 2536px) and (min-height: 1175px) {
  .oval {
    width: 790px;
    height: 670px;
    background: #ff0000;
    border-radius: 660px / 590px;
    background-color: #363535;
    background-blend-mode: soft-light;
  }
 }


